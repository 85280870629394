import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";

const AddNewUserDialog = ({
  show,
  onHide,
  newUser,
  setNewUser,
  handleInputChange,
  handleAddNewUser,
}) => {
  const [validated, setValidated] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    const isValid =
      newUser.firstName.trim() !== "" &&
      newUser.lastName.trim() !== "" &&
      /\S+@\S+\.\S+/.test(newUser.email);
    setIsFormValid(isValid);
  }, [newUser]);

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (!isFormValid) return;

    handleAddNewUser();
    setValidated(false);
  };

  const handleClose = () => {
    setValidated(false); // Reset validation state
    setNewUser({ firstName: "", lastName: "", email: "" }); // Reset form fields
    onHide(); // Close modal
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Add New User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="firstName">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              type="text"
              name="firstName"
              value={newUser.firstName}
              onChange={handleInputChange}
              placeholder="Enter first name"
              required
            />
            <Form.Control.Feedback type="invalid">
              Please provide a first name.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="lastName">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              type="text"
              name="lastName"
              value={newUser.lastName}
              onChange={handleInputChange}
              placeholder="Enter last name"
              required
            />
            <Form.Control.Feedback type="invalid">
              Please provide a last name.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="email">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              name="email"
              value={newUser.email}
              onChange={handleInputChange}
              placeholder="Enter email"
              required
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid email.
            </Form.Control.Feedback>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button
          variant="primary"
          type="submit"
          onClick={handleSubmit}
          disabled={!isFormValid}
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddNewUserDialog;
