import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  ToggleButtonGroup,
  ToggleButton,
} from "react-bootstrap";
import PanelsList from "./lists/PanelsList";
import UsersList from "./lists/UsersList";
import UsersListByPanel from "./lists/UsersListByPanel";
import PanelsListByUser from "./lists/PanelsListByUser";
import { useOAuthAccessToken } from "../../components/MobyAjax";

const ManageExistingPage = () => {
  const [selectedPanel, setSelectedPanel] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [viewMode, setViewMode] = useState("panel");
  const [panelsByUser, setPanelsByUser] = useState([]);

  const oauthAccessToken = useOAuthAccessToken();

  const handleViewModeChange = (val) => {
    setViewMode(val);
    setSelectedPanel(null);
    setSelectedUser(null);
  };

  const handleOnUserSelect = (val) => {
    if (val?.deviceId) {
      const updatedPanels = val.deviceId.map((deviceId) => ({
        deviceId: deviceId,
      }));
      setPanelsByUser(updatedPanels);
    } else {
      setPanelsByUser([]);
    }
  };

  return (
    <Container fluid>
      <Row className="mt-3">
        <Col>
          <ToggleButtonGroup
            type="radio"
            name="viewMode"
            value={viewMode}
            onChange={handleViewModeChange}
          >
            <ToggleButton
              id="toggle-panel"
              value="panel"
              variant={viewMode === "panel" ? "primary" : "outline-primary"}
            >
              Search by Panel
            </ToggleButton>
            <ToggleButton
              id="toggle-user"
              value="user"
              variant={viewMode === "user" ? "primary" : "outline-primary"}
            >
              Search by User
            </ToggleButton>
          </ToggleButtonGroup>
        </Col>
      </Row>

      <Row className="mt-3">
        {viewMode === "panel" && (
          <>
            <Col md={selectedPanel ? 6 : 12}>
              <PanelsList
                onPanelSelect={setSelectedPanel}
                token={oauthAccessToken}
              />
            </Col>
            {selectedPanel && (
              <Col md={6}>
                <UsersListByPanel
                  panel={selectedPanel}
                  setSelectedPanel={setSelectedPanel}
                  token={oauthAccessToken}
                />
              </Col>
            )}
          </>
        )}

        {viewMode === "user" && (
          <>
            <Col md={selectedUser ? 6 : 12}>
              <UsersList
                onUserSelect={handleOnUserSelect}
                setSelectedUser={setSelectedUser}
                token={oauthAccessToken}
              />
            </Col>
            {selectedUser && (
              <Col md={6}>
                <PanelsListByUser
                  panelsByUser={panelsByUser}
                  selectedUser={selectedUser}
                  setSelectedUser={setSelectedUser}
                  token={oauthAccessToken}
                />
              </Col>
            )}
          </>
        )}
      </Row>
    </Container>
  );
};

export default ManageExistingPage;
